
import {defineComponent, ref} from "vue";
import Swal from "sweetalert2/dist/sweetalert2.js";
import {Actions} from "@/store/enums/StoreEnums";
import {useStore} from "vuex";
import router from "@/router";

export default defineComponent({
  name: "AddEmployeeForm",
  components: {},
  setup() {
    const store = useStore();
    const formRef = ref<null | HTMLFormElement>(null);
    const loading = ref<boolean>(false);

    const formData = ref({
      name: "",
      surname: "",
      phone: "",
      born_surname: "",
      birthday: "",
      gender: "",
      first_job: "",
      emp_department: "",
      different_employer: "",
      pensioner: "",
      seizure: "",
      cert_forklift: "",
      drive_licences: [],
      wilding_licences: [],
      id_location_state: "",
      id_date: "",
      first_company_name: "",
      first_job_from: "",
      first_job_to: "",
      pensioner_from: "",
      state: "",
      id: "",
      dic: "",
      address: "",
      work_location: "",
      center_number: "",
      contact_person: ""
    });
    const rules = ref({
      company_name: [
        {
          required: true,
          message: "Název společnosti je povinný",
          trigger: "change",
        },
      ],
      address: [
        {
          required: true,
          message: "Adresa je povinná",
          trigger: "change",
        },
      ],
      ic: [
        {
          required: true,
          message: "IČ je povinné",
          trigger: "change",
        },
      ],
      work_location: [
        {
          required: true,
          message: "Místo výkonu pracé je povinné",
          trigger: "change",
        },
      ],
      center_number: [
        {
          required: true,
          message: "Číslo střediska je povinné",
          trigger: "change",
        },
      ],
    });

    const submit = () => {
      if (!formRef.value) {
        return;
      }

      formRef.value.validate((valid) => {
        if (valid) {
          loading.value = true;
          router.push({name: "addEmployeeStep2"});
          return;
          store
            .dispatch(Actions.ADD_EMPLOYEE, formData.value)
            .then(() => {
              Swal.fire({
                text: "Uložení nového zaměstnance",
                icon: "success",
                buttonsStyling: false,
                confirmButtonText: "Zaměstnanec  vytvořen",
                customClass: {
                  confirmButton: "btn fw-bold btn-light-primary",
                },
              }).then(function () {
                loading.value = false;
              });
            })
            .catch(() => {
              Swal.fire({
                text: store.getters.getErrors[0],
                icon: "error",
                buttonsStyling: false,
                confirmButtonText: "Try again!",
                customClass: {
                  confirmButton: "btn fw-bold btn-light-danger",
                },
              }).then(function () {
                loading.value = false;
              });
            });
        }
      });
    };

    return {
      formData,
      rules,
      submit,
      formRef,
      loading
    };
  },
});
